import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetSites: null,
        isSitesLoading: false,
        searchSites: null,
        searchSitesPerCustomer: null,
        sitesPaginationPage: 1
    }),
    computed: {
        ...mapState('sites', [
            'totalSitesAmount'
        ]),
        ...mapGetters('sites', [
            'sites'
        ])
    },
    watch: {
        searchSites: {
            handler (val, oldVal) {
                if (!val || this.isSitesLoading || val === oldVal) {
                    return;
                }

                this.debouncedGetSites(val);
            }
        },
        searchSitesPerCustomer: {
            handler (val, oldVal) {
                if (!val || this.isSitesLoading || val === oldVal) {
                    return;
                }

                let customerId = null;

                if (this.customer_id) {
                    customerId = this.customer_id;
                }

                if (this.customerId) {
                    customerId = this.customerId;
                }

                if (this.filters?.[FILTERS.CUSTOMER_ID]) {
                    customerId = this.filters[FILTERS.CUSTOMER_ID].join(',');
                }

                if (this.modalFilters?.[FILTERS.CUSTOMER_ID]) {
                    customerId = this.modalFilters[FILTERS.CUSTOMER_ID].join(',');
                }

                this.debouncedGetSites(val, null, customerId);
            }
        }
    },
    methods: {
        getSites (val, searchID = null, customerId = null) {
            this.isSitesLoading = true;

            const params = {};

            if (searchID) {
                params['filter[id]'] = searchID;
            } else {
                params['filter[name][match]'] = val;
            }

            if (customerId) {
                const data = Array.isArray(customerId) ? customerId.join(',') : customerId;
                params['filter[customer_id]'] = data;
            }

            return this.$store.dispatch('sites/getSites', params)
                .then(() => {
                    this.sitesPaginationPage = 1;
                })
                .finally(() => {
                    this.isSitesLoading = false;
                });
        },
        getSitesLazyLoading (customerId = null) {
            if (this.totalSitesAmount > this.sites.length) {
                this.isSitesLoading = true;

                this.sitesPaginationPage += 1;

                const params = {
                    'page[number]': this.sitesPaginationPage
                };

                if (customerId || this.filters?.[FILTERS.CUSTOMER_ID] || this.modalFilters?.[FILTERS.CUSTOMER_ID]) {
                    let data = customerId ||
                        this.filters?.[FILTERS.CUSTOMER_ID] ||
                        this.modalFilters?.[FILTERS.CUSTOMER_ID];
                    data = Array.isArray(data) ? data.join(',') : data;
                    params['filter[customer_id]'] = data;
                }

                const _name = this.searchSites ||
                    this.searchSitesPerCustomer ||
                    this.filtersSearching?.[FILTERS_SEARCHING.SITE] ||
                    this.filtersSearching?.[FILTERS_SEARCHING.SITE_PER_CUSTOMER] ||
                    this.modalFiltersSearching?.[FILTERS_SEARCHING.SITE] ||
                    this.modalFiltersSearching?.[FILTERS_SEARCHING.SITE_PER_CUSTOMER];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('sites/getSitesLazyLoading', params)
                    .finally(() => {
                        this.isSitesLoading = false;
                    });
            }
        },
        referralSiteCallback (newVal) {
            const filterPropertyName = this.isModal ? 'modalFiltersConfig' : 'filtersConfig';
            const mutationTitle = this.isModal ? 'filters/SET_MODAL_CONFIG' : 'filters/SET_CONFIG';

            if (newVal && newVal.length > 0) {
                this[filterPropertyName].find(el => el.model === FILTERS.LOCATION_ID).disabled = false;
                this[filterPropertyName].find(el => el.model === FILTERS.LOCATION_ID).successMessages = '';
                this.debouncedGetLocations(null, null, newVal);
            } else {
                this[filterPropertyName].find(el => el.model === FILTERS.LOCATION_ID).disabled = true;
                this[filterPropertyName].find(el => el.model === FILTERS.LOCATION_ID).successMessages = this.$t('messages.choose_site');

                this.$refs.filtersBar.resetSpecifyFilter(FILTERS.LOCATION_ID);
            }
            this.$store.commit(mutationTitle, this[filterPropertyName]);
        }
    },
    created () {
        this.debouncedGetSites = debounce(this.getSites, 1000);
    }
};
