<template>
    <Modal :modal-title="$t('defined_filters.presets_modal_title')"
           :dialog="dialog"
           max-width="1000px"
           fullWidth
           fullHeight
           scrollable
           @close="close">
        <template slot="content">
            <v-list class="py-0" two-line>
                <v-list-item-group multiple v-model="selected">
                    <template v-for="(preset, index) in presets">
                        <v-list-item :key="preset.id" @click="toggleVisiblePreset(preset)" :value="preset.id">
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{preset.name}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <FiltersChips :filters="mapFiltersFromPreset(preset)" column />
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="canEditIncident">
                                    <HintingIcon :icon="ICONS.EDIT"
                                        :tooltip="$t('actions.edit')"
                                        @click="editPreset(preset)"
                                        class-name="mr-2"
                                        stop
                                    />
                                </v-list-item-action>
                                <v-list-item-action v-if="canEditIncident">
                                    <ButtonWithConfirmation :item-id="preset.id" :callback="deletePreset">
                                    </ButtonWithConfirmation>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                        <v-divider
                            v-if="index < presets.length - 1"
                            :key="`${preset.id}-divider`"
                          ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
            <UpdatePresetModal
                v-if="canEditIncident && presetToEdit"
                :dialog="editModalVisibility"
                :preset="presetToEdit"
                @close="editModalVisibility = false"
            />
        </template>
        <template slot="footer">
            <v-btn
                color="secondary"
                outlined
                @click="close"
            >
                {{ $t('buttons.ok') }}
            </v-btn>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation.vue';
import HintingIcon from '@/components/widgets/HintingIcon.vue';
import UpdatePresetModal from '@/components/widgets/tables/FilterPresets/UpdatePresetModal.vue';
import { ICONS } from '@/constants/icons';
import FiltersChips from '@/components/widgets/tables/FilterPresets/FiltersChips.vue';
import { mapFiltersFromPreset } from '@/components/widgets/tables/FilterPresets/utils';
import { mapState } from 'vuex';

export default {
    name: 'FilterPresetsModal',
    components: { FiltersChips, UpdatePresetModal: UpdatePresetModal, HintingIcon, ButtonWithConfirmation, Modal },
    data: () => ({
        editModalVisibility: false,
        presetToEdit: null,
        selected: [],
        ICONS
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState('filterPresets', [
            'presets',
            'visiblePresets'
        ]),
        canEditIncident () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT);
        }
    },
    methods: {
        mapFiltersFromPreset,
        close () {
            this.$emit('close');
        },
        toggleVisiblePreset (preset) {
            const isVisible = this.visiblePresets.find(visiblePreset => visiblePreset === preset.id);

            if (isVisible) {
                return this.$store.dispatch('filterPresets/hidePreset', preset.id);
            } else {
                return this.$store.commit('filterPresets/SHOW_PRESET', preset.id);
            }
        },
        editPreset (preset) {
            this.presetToEdit = preset;
            this.editModalVisibility = true;
        },
        deletePreset (id) {
            return this.$store.dispatch('filterPresets/deletePreset', id);
        }
    },
    watch: {
        dialog (val) {
            if (val && this.presets.length === 0) {
                this.$store.dispatch('filterPresets/fetchPresets');
            }
        },
        visiblePresets (val) {
            if (val.length === 0) {
                this.selected = [];
            } else {
                this.selected = this.visiblePresets;
            }
        }
    },
    mounted () {
        this.selected = this.visiblePresets;
    }
};
</script>
