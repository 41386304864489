<template>
    <FieldsetGroup :label="label"
                   :flat="flat"
                   :required="isRequired">
        <template slot="content">
            <div class="inner-content">
                <v-layout v-for="(field, i) in values"
                          :key="i">
                    <v-flex>
                        <TextField :name="field.name"
                                   label=" "
                                   :hide-required-mark="true"
                                   :rules="rules"
                                   v-model="field.model"></TextField>
                    </v-flex>
                    <v-flex class="grow--0 ml-3 buttons">
                        <v-icon class="clickable"
                                @click="deleteField(i)"
                                size="20"
                                :disabled="values.length <= 1"
                                color="secondary">{{ ICONS.DELETE }}</v-icon>
                        <v-icon class="clickable"
                                @click="addField"
                                size="20"
                                :disabled="i !== values.length - 1"
                                color="secondary">{{ ICONS.PLUS }}</v-icon>
                    </v-flex>
                </v-layout>
            </div>
        </template>
    </FieldsetGroup>
</template>

<script>
import TextField from '@/components/widgets/forms/TextField';
import FieldsetGroup from '@/components/widgets/forms/FieldsetGroup';
import { ICONS } from '@/constants/icons';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'MultiText',
    components: { TextField, FieldsetGroup },
    mixins: [isFieldRequired],
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            required: true
        },
        value: {},
        clear: {
            type: Boolean,
            'default': false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        flat: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        values: [],
        item: {
            name: null,
            model: ''
        },
        ICONS
    }),
    watch: {
        values: {
            handler (val) {
                const _input = val.map(el => el.model).filter(el => el);
                this.$emit('input', _input);
            },
            deep: true
        },
        clear: {
            handler (val) {
                if (!val) {
                    this.initValues();
                }
            }
        }
    },
    methods: {
        getNextIndex (arr) {
            let index = 0;
            if (arr.length > 0) {
                const _arr = arr.map(el => parseInt(el.name.match(/\d/g).join('')));
                index = _arr.reduce((m, n) => Math.max(m, n)) + 1;
            }

            return index;
        },
        addField () {
            const _item = { ...this.item };
            const _index = this.getNextIndex(this.values);

            _item.name = `${this.name}_${_index}`;

            this.values.push(_item);
        },
        deleteField (index) {
            this.values.splice(index, 1);
        },
        parseValue () {
            this.value.forEach((el, i) => {
                const _item = { ...this.item };
                _item.name = `${this.name}_${i}`;
                _item.model = el;

                this.values.push(_item);
            });
        },
        initValues () {
            this.values = [];
            if (this.value && this.value.length > 0) {
                this.parseValue();
            } else {
                this.addField();
            }
        }
    },
    mounted () {
        this.initValues();
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables";

    fieldset {
        border: none;
        padding: 0 8px;
        margin-bottom: 24px;
        max-height: 210px;
        overflow-y: hidden;
    }

    legend {
        color: $text_lighter;
        font-size: $xs;
    }

    .buttons {
        width: calc(20px * 2 + 8px);
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .inner-content {
        max-height: 170px;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
</style>
