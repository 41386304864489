<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <TableTitle :title="$t('labels.constant_questions')"
                    @click="showQuestionForm"
                    class="mb-2"
                    data-test="TableTitle"
                    :icon="ICONS.ADD_QUESTION"
                    :button-visibility="showAddButton"
                    :button-title="$t('buttons.add_new_question')"></TableTitle>

        <DraggableTable :items="constantQuestions"
                        :with-pagination="false"
                        :is-draggable="editable"
                        @drag-update="updateQuestionsList($event)"
                        :headers="headers">
            <template v-slot:item.notifications="{ item }">
                <div v-if="showAnswer(item, 'condition')">
                    <p class="my-1">{{ $t('labels.condition') }}: {{ conditionTitle(item) }}</p>
                    <p class="mb-1">{{ $t('labels.sms_confirmation_content') }}: {{ item.smsContent }}</p>
                </div>
                <span v-else></span>
            </template>

            <template v-slot:item.parameters="{ item }">
                <span class="break-word">{{ parseParams(item.parameters) }}</span>
            </template>

            <template v-slot:item.repeated="{ item }">
                <span v-if="showAnswer(item, 'repeated')">{{ convertBoolean(item.repeated) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.required="{ item }">
                <span v-if="showAnswer(item, 'required')">{{ convertBoolean(item.required) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.notApplicable="{ item }">
                <span v-if="showAnswer(item, 'notApplicable')">{{ convertBoolean(item.notApplicable) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.includeInScanField="{ item }">
                <span v-if="showAnswer(item, 'includeInScanField')">{{ convertBoolean(item.includeInScanField) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.autocomplete="{ item }">
                <span v-if="showAnswer(item, 'autocomplete')">{{ convertBoolean(item.autocomplete) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.actions="{ item }" v-if="editable">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditQuestion"
                             @click="showQuestionForm(item)"
                             data-test="editIncidentBtn"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveQuestion"
                                        :callback="deleteQuestion"></ButtonWithConfirmation>
            </template>
        </DraggableTable>
        <br />
        <TableTitle :title="$t('labels.random_questions')"
                    class="mb-2"
                    :button-visibility="false"
                    data-test="TableTitle"></TableTitle>
        <DraggableTable :items="randomQuestions"
                        :with-pagination="false"
                        :is-draggable="false"
                        @drag-update="updateQuestionsList($event)"
                        :headers="headers">
            <template v-slot:item.sequence="{ item }">
                <span>{{ randomQuestions.findIndex(el =>  el.id === item.id) + 1 }}</span>
            </template>

            <template v-slot:item.notifications="{ item }">
                <div v-if="showAnswer(item, 'condition')">
                    <p class="my-1">{{ $t('labels.condition') }}: {{ conditionTitle(item) }}</p>
                    <p class="mb-1">{{ $t('labels.sms_confirmation_content') }}: {{ item.smsContent }}</p>
                </div>
                <span v-else></span>
            </template>

            <template v-slot:item.parameters="{ item }">
                <span class="break-word">{{ parseParams(item.parameters) }}</span>
            </template>

            <template v-slot:item.repeated="{ item }">
                <span v-if="showAnswer(item, 'repeated')">{{ convertBoolean(item.repeated) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.required="{ item }">
                <span v-if="showAnswer(item, 'required')">{{ convertBoolean(item.required) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.notApplicable="{ item }">
                <span v-if="showAnswer(item, 'notApplicable')">{{ convertBoolean(item.notApplicable) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.includeInScanField="{ item }">
                <span v-if="showAnswer(item, 'includeInScanField')">{{ convertBoolean(item.includeInScanField) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.autocomplete="{ item }">
                <span v-if="showAnswer(item, 'autocomplete')">{{ convertBoolean(item.autocomplete) }}</span>
                <span v-else></span>
            </template>

            <template v-slot:item.actions="{ item }" v-if="editable">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditQuestion"
                             @click="showQuestionForm(item)"
                             data-test="editIncidentBtn"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveQuestion"
                                        :callback="deleteQuestion"></ButtonWithConfirmation>
            </template>
        </DraggableTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import TableTitle from '@/components/widgets/tables/TableTitle';
import { TYPES } from '@/constants/questionTypes';
import { ICONS } from '@/constants/icons';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import HintingIcon from '@/components/widgets/HintingIcon';
import DraggableTable from '@/components/widgets/tables/DraggableTable';

export default {
    name: 'QuestionsTable',
    components: { TableTitle, ButtonWithConfirmation, HintingIcon, DraggableTable },
    props: {
        items: {
            type: Array,
            'default': () => ([])
        },
        editable: {
            type: Boolean,
            'default': true
        }
    },
    data: () => ({
        localItems: [],
        ICONS,
        deletedItem: null,
        headers: []
    }),
    computed: {
        ...mapState('manageTemplate', [
            'editedTemplateId'
        ]),
        constantQuestions () {
            return this.localItems.filter(el => !el.random);
        },
        randomQuestions () {
            return this.localItems.filter(el => el.random);
        },
        basicHeaders () {
            return [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'sequence',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.type'),
                    value: 'typeName',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$tc('labels.question', 1),
                    value: 'question',
                    icon: false,
                    sortable: false,
                    width: '200px'
                },
                {
                    text: this.$t('labels.question_parameters'),
                    value: 'parameters',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.notifications'),
                    value: 'notifications',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.incident'),
                    value: 'incidentName',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.repetitive_question'),
                    value: 'repeated',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.required_question'),
                    value: 'required',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.not_applicable'),
                    value: 'notApplicable',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.include_in_scan_field'),
                    value: 'includeInScanField',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.autocomplete'),
                    value: 'autocomplete',
                    icon: false,
                    sortable: false
                }
            ];
        },
        editableHeaders () {
            return [
                ...this.basicHeaders,
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '100px',
                    sortable: false
                }
            ];
        },
        canAddQuestion () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.TEMPLATE_QUESTIONS);
        },
        canEditQuestion () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TEMPLATE_QUESTIONS);
        },
        canRemoveQuestion () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.TEMPLATE_QUESTIONS);
        },
        showAddButton () {
            return this.editable && this.canAddQuestion;
        }
    },
    watch: {
        items: {
            handler (val) {
                this.localItems = [...val];
                this.localItems.sort((a, b) => {
                    return a.sequence - b.sequence;
                });
            },
            deep: true,
            immediate: true
        },
        editable: {
            handler (val) {
                this.headers = val ? this.editableHeaders : this.basicHeaders;
            },
            immediate: true
        }
    },
    methods: {
        parseParams (params) {
            return params.join(', ');
        },
        showQuestionForm (item = null) {
            if (item) {
                this.$store.commit('manageTemplateQuestion/SET_QUESTION_DATA', item);
                this.$store.commit('manageTemplateQuestion/SET_EDITED_QUESTION_ID', item.id);
                this.$nextTick(() => {
                    this.$store.commit('manageTemplateQuestion/SET_QUESTION_FORM_VISIBILITY', true);
                });
            } else {
                this.$store.commit('manageTemplateQuestion/SET_QUESTION_FORM_VISIBILITY', true);
            }
        },
        conditionTitle (item) {
            if (item.condition && item.type !== TYPES.YES_NO.toString()) {
                return item.condition;
            } else if (item.condition && item.type === TYPES.YES_NO.toString()) {
                return item.condition === 1 ? this.$t('answers.yes') : this.$t('answers.no');
            } else {
                return '';
            }
        },
        convertBoolean (val) {
            return val ? this.$t('answers.yes') : this.$t('answers.no');
        },
        showAnswer (val, key) {
            const params = val.getFormParams();
            return params[key];
        },
        deleteQuestion (id) {
            return this.$store.dispatch('manageTemplate/deleteQuestion', id);
        },
        updateQuestionsList (event) {
            const result = [...event];
            result.forEach((el, i) => {
                el.sequence = i + 1;
            });

            this.$store.commit('manageTemplate/SET_QUESTIONS_LIST', [...result, ...this.randomQuestions]);
        }
    }
};
</script>

<style scoped lang="scss">
    .break-word {
        word-break: break-word;
    }
</style>
