<template>
    <a class="text--s font-weight-regular"
       v-if="asLink"
       @click="getPdf">
        <CircularLoader v-bind="loaderProps" v-if="loader"></CircularLoader>
        <span v-else>
            <slot name="icon">
                <v-icon size="20">{{ ICONS.DOWNLOAD }}</v-icon>
            </slot>
        </span>
        <span v-if="withTitle">{{ buttonTitle }}</span>
    </a>
    <v-btn v-else text @click="getPdf" :block="asBlock">
        <CircularLoader v-bind="loaderProps" v-if="loader"></CircularLoader>
        <span v-else>
            <slot name="icon">
                <v-icon left size="20">{{ ICONS.DOWNLOAD }}</v-icon>
            </slot>
        </span>
        <span v-if="withTitle">{{ buttonTitle }}</span>
    </v-btn>
</template>

<script>
import { ICONS } from '@/constants/icons';
import CircularLoader from '@/components/widgets/CircularLoader';

export default {
    name: 'GetFile',
    components: { CircularLoader },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        withTitle: {
            type: Boolean,
            'default': true
        },
        title: {
            type: String,
            'default': ''
        },
        asLink: {
            type: Boolean,
            'default': false
        },
        asBlock: {
            type: Boolean,
            'default': false
        },
        loaderProps: {
            type: Object,
            'default': () => ({})
        }
    },
    data: () => ({
        ICONS
    }),
    computed: {
        buttonTitle () {
            return this.title || this.$t('buttons.download_pdf');
        }
    },
    methods: {
        getPdf () {
            this.$emit('get-file');
        }
    }
};
</script>

<style scoped>

</style>
