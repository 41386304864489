<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="defined">
        <v-row align-sm="start">
            <v-col cols="12" sm="auto" class="pb-0 pt-md-0">
                <v-btn
                    block
                    @click="modalVisibility = !modalVisibility"
                >
                    <v-icon left>
                      {{ICONS.ADD_FILTER}}
                    </v-icon>
                    {{ $t('labels.filters') }}
                    <v-badge
                        color="secondary"
                        inline
                        tile
                        v-if="modalFiltersCount"
                        :content="modalFiltersCount"
                    />
                </v-btn>
            </v-col>
           <v-col sm="auto" class="pb-0 pt-md-0">
                <v-btn
                    block
                    @click="savedModalVisibility = !savedModalVisibility"
                >
                    <v-icon left>
                      {{ICONS.TABLE_FILTER}}
                    </v-icon>
                    {{ $t('labels.defined_filters') }}
                </v-btn>
            </v-col>
            <v-spacer class="d-none d-sm-flex" />
            <v-col
                :lg="Math.max(minWidth, 3)"
                :md="Math.max(minWidth, 4)"
                :sm="Math.max(minWidth, 6)"
                cols="12"
                class="pb-0 pt-md-0"
                v-for="(filter, i) in barFilters"
                :key="i"
                align-self="end"
            >
                <component
                    :is="filter.type"
                    :search-input.sync="filtersSearching[filter.search]"
                    v-on="setListeners(filter)"
                    v-model="filterItems[filter.model]"
                    v-bind="setAttributes(filter)"
                    hide-details
                />
            </v-col>
            <v-col cols="auto" sm="auto" class="pb-0 pt-md-0" ref="resetBtn">
                <v-btn @click="reset" :disabled="!(activeFiltersCount || activePreset)">
                    <v-icon color="secondary">
                        {{ ICONS.CLEAN_FILTER }}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <FiltersModal :dialog="modalVisibility" @close="closeModal">
            <v-row class="modal-body">
                <v-col
                    :md="Math.max(minWidth, 4)"
                    :sm="Math.max(minWidth, 6)"
                    cols="12"
                    v-for="(filter, i) in modalFilters"
                    :key="i"
                    align-self="end"
                >
                    <component
                        :is="filter.type"
                        :search-input.sync="filtersSearching[filter.search]"
                        v-on="setListeners(filter)"
                        v-model="filterItems[filter.model]"
                        v-bind="setAttributes(filter)"
                        hide-details
                    />
                </v-col>
            </v-row>
        </FiltersModal>
        <FilterPresetsModal :dialog="savedModalVisibility" @close="closeSavedModal" />
    </div>
    <div v-else>
        <v-row align="start"
                :class="{'nowrap': !alwaysMinified && $vuetify.breakpoint.mdAndUp, 'px-3': alwaysMinified || $vuetify.breakpoint.smAndDown, 'mx-0': true }">
            <div class="full-width">
                <v-row align="center"
                       :justify="justifyAlignment">
                    <v-col :lg="Math.max(minWidth, 3)"
                           :md="Math.max(minWidth, 4)"
                           :sm="Math.max(minWidth, 6)"
                           cols="12"
                           class="py-0"
                           v-for="(filter, i) in localConfig"
                           :key="i">
                        <component :is="filter.type"
                                   v-if="hideBreakpoint(i)"
                                   :search-input.sync="filtersSearching[filter.search]"
                                   v-on="setListeners(filter)"
                                   :data-test="filter.model"
                                   v-model="filterItems[filter.model]"
                                   v-bind="setAttributes(filter)">
                        </component>
                        <transition name="fade"
                                    v-if="!hideBreakpoint(i) && showFilters">
                            <component :is="filter.type"
                                       :search-input.sync="filtersSearching[filter.search]"
                                       v-on="setListeners(filter)"
                                       :data-test="filter.model"
                                       v-model="filterItems[filter.model]"
                                       v-bind="setAttributes(filter)"></component>
                        </transition>
                    </v-col>
                </v-row>
                <v-row justify="center"
                       class="mb-3"
                       v-if="(alwaysMinified || $vuetify.breakpoint.smAndDown) && showFilters">
                    <v-btn color="secondary"
                           @click="reset">{{ $t('buttons.reset_filters') }}</v-btn>
                </v-row>
            </div>
            <div v-if="!alwaysMinified && $vuetify.breakpoint.mdAndUp"
                   class="ml-3 noshrink">
                <v-icon size="24"
                        color="secondary"
                        data-test="resetFilters"
                        class="mt-2 mr-2"
                        @click="reset">{{ ICONS.CLEAN_FILTER }}</v-icon>
                <v-icon v-if="buttonVisibility"
                        size="28"
                        data-test="toggleFilters"
                        color="secondary"
                        class="mt-2"
                        @click="showFilters = !showFilters">{{ buttonIcon }}</v-icon>
            </div>
            <v-col cols="12"
                   v-if="alwaysMinified || $vuetify.breakpoint.smAndDown"
                   class="py-0">
                <v-layout justify-center>
                    <v-btn color="secondary"
                            text
                            @click="showFilters = !showFilters">{{ buttonTitle }}</v-btn>
                </v-layout>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DatePicker from '@/components/widgets/forms/DatePicker';
import YearPicker from '@/components/widgets/forms/YearPicker';
import MonthPicker from '@/components/widgets/forms/MonthPicker';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import { FILTERS, FILTERS_SEARCHING, FILTERS_V2 } from '@/constants/filtersModelNames';
import { ICONS } from '@/constants/icons';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import YearPickerFilter from '@/models/filters/yearPickerFilter';
import MonthPickerFilter from '@/models/filters/monthPickerFilter';
import FiltersModal from '@/components/widgets/tables/FiltersModal.vue';
import FilterPresetsModal from '@/components/widgets/tables/FilterPresets/FilterPresetsModal.vue';
import { isFilterActive } from '@/components/widgets/tables/FilterPresets/utils';

const defaultFilters = () => {
    const result = {};

    Object.keys(FILTERS).forEach(k => {
        result[FILTERS[k]] = null;
    });

    Object.keys(FILTERS_V2).forEach(k => {
        result[FILTERS_V2[k]] = null;
    });

    return result;
};
const defaultFiltersSearching = () => {
    const result = {};

    Object.keys(FILTERS_SEARCHING).forEach(k => {
        result[FILTERS_SEARCHING[k]] = null;
    });

    return result;
};

export default {
    name: 'Filters',
    components: { FilterPresetsModal, FiltersModal, DatePicker, Autocomplete, YearPicker, MonthPicker },
    props: {
        isModal: {
            type: Boolean,
            'default': false
        },
        alwaysMinified: {
            type: Boolean,
            'default': false
        },
        minWidth: {
            type: Number,
            'default': 1
        },
        withoutBarFilters: {
            type: Boolean,
            'default': false
        },
        defined: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        showFilters: false,
        filterItems: defaultFilters(),
        filtersSearching: defaultFiltersSearching(),
        ICONS,
        modalVisibility: false,
        savedModalVisibility: false
    }),
    computed: {
        ...mapState('filters', [
            'config',
            'modalConfig',
            'filters'
        ]),
        ...mapState('filterPresets', [
            'visiblePresets',
            'activePreset'
        ]),
        localConfig () {
            const allFilters = this.isModal ? this.modalConfig : this.config;
            return this.withoutBarFilters ? allFilters.filter(f => !f.showInBar) : allFilters;
        },
        modalFiltersCount () {
            const barFilterKeys = this.barFilters.map(f => f.model);
            return Object.keys(this.filterItems).filter(key => !barFilterKeys.includes(key) && isFilterActive(this.filterItems[key])).length;
        },
        activeFiltersCount () {
            return Object.keys(this.filterItems).filter(key => isFilterActive(this.filterItems[key])).length;
        },
        barFilters () {
            return this.$vuetify.breakpoint.lgAndUp ? this.localConfig.filter(f => f.showInBar) : [];
        },
        modalFilters () {
            return this.$vuetify.breakpoint.lgAndUp ? this.localConfig.filter(f => !f.showInBar) : this.localConfig;
        },
        buttonTitle () {
            return this.showFilters ? this.$t('buttons.hide_filters') : this.$t('buttons.show_filters');
        },
        buttonIcon () {
            return this.showFilters ? ICONS.SINGLE_UP : ICONS.SINGLE_DOWN;
        },
        buttonVisibility () {
            if (this.alwaysMinified) {
                return true;
            } else {
                if (this.$vuetify.breakpoint.lgAndUp && this.localConfig.length > 4) {
                    return true;
                }
                if (this.$vuetify.breakpoint.md && this.localConfig.length > 3) {
                    return true;
                }
                return this.$vuetify.breakpoint.smAndDown;
            }
        },
        justifyAlignment () {
            return this.buttonVisibility ? 'start' : 'end';
        }
    },
    watch: {
        filterItems: {
            handler (val) {
                this.setFilters(val);
            },
            deep: true
        },
        filtersSearching: {
            handler (val) {
                this.setFiltersSearching(val);
            },
            deep: true
        },
        activePreset: {
            handler (val) {
                if (val !== null) {
                    this.filterItems = defaultFilters();
                    this.filterItems[FILTERS_V2.START_DATE] = this.filters[FILTERS_V2.START_DATE];
                    this.filterItems[FILTERS_V2.END_DATE] = this.filters[FILTERS_V2.END_DATE];
                    this.setFilters(this.filterItems);
                }
            }
        }
    },
    methods: {
        closeModal () {
            this.modalVisibility = false;
        },
        closeSavedModal () {
            this.savedModalVisibility = false;
        },
        sortItems (items) {
            const type = typeof items?.[0];
            if (type === 'object') {
                return [...items].sort((a, b) => (a?.text.toUpperCase() > b?.text.toUpperCase()) ? 1 : -1);
            } else return [...items].sort();
        },
        setFilters (val) {
            if (this.isModal) {
                return this.$store.commit('filters/SET_MODAL_FILTERS', val);
            } else {
                return this.$store.commit('filters/SET_FILTERS', val);
            }
        },
        setFiltersSearching (val) {
            if (this.isModal) {
                return this.$store.commit('filters/SET_MODAL_FILTERS_SEARCHING', val);
            } else {
                return this.$store.commit('filters/SET_FILTERS_SEARCHING', val);
            }
        },
        setItems (items) {
            if (items && Array.isArray(items)) {
                return items;
            } else if (items) {
                return items.obj === 'getters' ? this.$store.getters[`${items.module}/${items.prop}`] : this.$store.state[items.module][items.prop];
            }
        },
        hideBreakpoint (index) {
            if (this.alwaysMinified) {
                return false;
            } else {
                if (this.$vuetify.breakpoint.lgAndUp && index <= 3) {
                    return true;
                }
                return this.$vuetify.breakpoint.md && index <= 2;
            }
        },
        reset () {
            this.filterItems = defaultFilters();
            this.setFilters(this.filterItems);
            this.$store.commit('filterPresets/SET_ACTIVE_PRESET', null);
            this.$emit('reset');
        },
        clearFilters () {
            this.filterItems = defaultFilters();
        },
        resetSpecifyFilter (prop) {
            this.filterItems[prop] = null;
        },
        setSpecifyFilter (prop, val) {
            this.filterItems[prop] = val;
        },
        setAttributes (item) {
            if (item instanceof AutocompleteFilter) {
                return this.autocompleteAttributes(item);
            }
            if (item instanceof DatePickerFilter) {
                return this.datePickerAttributes(item);
            }
            if (item instanceof YearPickerFilter) {
                return this.yearPickerAttributes(item);
            }
            if (item instanceof MonthPickerFilter) {
                return this.monthPickerAttributes(item);
            }
        },
        autocompleteAttributes (item) {
            return {
                label: item.label,
                loading: item.loading,
                'no-filter': true,
                items: this.sortItems(this.setItems(item.items)),
                disabled: item.disabled,
                'success-messages': item.successMessages,
                multiple: item.multiple,
                clearable: item.clearable,
                'return-object': item.returnObject
            };
        },
        datePickerAttributes (item) {
            return {
                label: item.label,
                clearable: true
            };
        },
        yearPickerAttributes (item) {
            return {
                label: item.label,
                max: item.max
            };
        },
        monthPickerAttributes (item) {
            return {
                label: item.label
            };
        },
        setListeners (item) {
            if (item instanceof AutocompleteFilter) {
                return this.autocompleteListeners(item);
            }
        },
        autocompleteListeners (item) {
            const listeners = {};

            if (item.hasProperty('lazy')) {
                listeners['load-more-items'] = item.lazy;
            }

            return listeners;
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-input__slot {
            margin-bottom: 0;
        }
        & .v-text-field__details {
            margin-top: 5px;
        }
        & .v-input--is-disabled {
            & .v-messages__message {
                font-size: $xxs;
            }
        }
        & .v-badge--inline .v-badge__wrapper {
            margin-right: -4px;
            margin-left: 8px;
        }
        & .v-badge--tile .v-badge__badge {
            border-radius: 2px;
        }
    }
    .modal-body {
        margin-top: -12px;
        margin-bottom: -12px;
    }
</style>
