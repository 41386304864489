<template>
    <div>
        <v-btn text block
           @click="dialog = true">
            <v-icon left size="20">{{ ICONS.IMPORT }}</v-icon>
            {{ $t('buttons.import_template') }}
        </v-btn>

        <Modal :dialog="dialog"
               :modal-title="$t('buttons.import_template')"
               max-width="400px"
               dense
               data-test="modal"
               @close="close"
               @visibility="dialog = $event">
            <template slot="content">
                <ImportTemplateForm ref="form"
                                    @fetch="$emit('fetch')"></ImportTemplateForm>
            </template>
        </Modal>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import Modal from '@/components/templates/Modal';
import ImportTemplateForm from '@/components/templatesConfigurations/import/ImportTemplateForm';

export default {
    name: 'ImportTemplate',
    components: { Modal, ImportTemplateForm },
    data: () => ({
        ICONS,
        dialog: false
    }),
    methods: {
        close () {
            this.dialog = false;
            this.file = [];
            this.$refs.form.clearFiles();
        }
    }
};
</script>

<style scoped>

</style>
